import { render, staticRenderFns } from "./indexDetails.vue?vue&type=template&id=1a634db0&scoped=true&"
import script from "./indexDetails.vue?vue&type=script&lang=js&"
export * from "./indexDetails.vue?vue&type=script&lang=js&"
import style0 from "./indexDetails.vue?vue&type=style&index=0&id=1a634db0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a634db0",
  null
  
)

export default component.exports